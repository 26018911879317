import { Component, OnInit } from '@angular/core';
import { collections } from 'src/app/pages/account/my-collection/data';
import { favorite } from 'src/app/pages/account/favorite/data';
import { items } from 'src/app/pages/account/my-item/data';
import { AuthService } from '../services/auth.service';  // Serviço de autenticação para logout e dados do usuário
import { RaffleService } from '../services/raffle.service';  // Serviço de rifas para buscar rifas do creator
import { Router } from '@angular/router';  // Para redirecionar após logout

@Component({
  selector: 'app-acount-sidemenu',
  templateUrl: './acount-sidemenu.component.html',
  styleUrls: ['./acount-sidemenu.component.scss']
})

export class AcountSidemenuComponent implements OnInit {

  public isCollapsed = true;
  favorites: any;
  collections: any;
  myitems: any;
  raffleCount: number = 0;  // Contador de rifas

  constructor(
    private authService: AuthService,  // Injetando o serviço de autenticação
    private raffleService: RaffleService,  // Injetando o serviço de rifas
    private router: Router  // Injetando o roteador para redirecionar
  ) {
    // Fetch Data
    this.favorites = favorite;
    this.collections = collections;
    this.myitems = items;
  }

  ngOnInit(): void {
    // Adiciona a lógica para definir o menu ativo
    setTimeout(() => {
      const pathName = window.location.pathname;
      const items = Array.from(document.querySelectorAll("a.menulist"));
      let matchingMenuItem = items.find((x: any) => {
        return x.pathname === pathName;
      });
      matchingMenuItem?.classList.add('active');
    }, 0);

    // Obter o ID do usuário logado e buscar as rifas do creator
    this.authService.getUserProfile().subscribe(user => {
      if (user.id) {
        this.raffleService.getRafflesByCreator(user.id).subscribe(raffles => {
          this.raffleCount = raffles.length;  // Define o número de rifas
        });
      }
    });
  }

  // Função de logout
  logout(): void {
    this.authService.logout().subscribe(
      () => {
        this.router.navigate(['/']);  // Redireciona para a raiz após o logout
      },
      error => {
        console.error('Erro ao fazer logout:', error);
      }
    );
  }

}
