import { Component, OnInit } from '@angular/core';
import { QuotaService } from '../../../shared/services/quota.service'; // Serviço de Quota
import { Quota } from '../../../shared/models/quota.model'; // Modelo de Quota

@Component({
  selector: 'app-auction-ended',
  templateUrl: './auction-ended.component.html',
  styleUrls: ['./auction-ended.component.scss']
})

// AuctionEnded Component
export class AuctionEndedComponent implements OnInit {

  breadCrumbItems!: any;
  morecollection: any;
  quotas: Quota[] = []; // Array para armazenar as quotas encontradas
  contact: string = ''; // Campo para buscar por contato
  errorMessage: string | null = null;

  constructor(private quotaService: QuotaService) { }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;

    // Remove header user profile and create button
    document.querySelector('.user')?.classList.add('d-none');
    document.querySelector('.create')?.classList.add('d-none');
    document.querySelector('.craeteitem')?.classList.add('d-none');

    // BreadCrumb
    this.breadCrumbItems = [
      { label: 'Home', link: '/' },
      { label: 'Marketplace', link: '/' },
      { label: 'Single Project', active: true, link: '/Single Project' }
    ];
  }

  // Método para buscar cotas por contato (e-mail ou telefone)
  searchQuotasByContact(): void {
    if (this.contact) {
      this.quotaService.getQuotaByContact(this.contact).subscribe(
        (data) => {
          this.quotas = data;
          this.errorMessage = null;
        },
        (error) => {
          this.errorMessage = 'Nenhuma cota encontrada para esse contato.';
          this.quotas = [];
        }
      );
    }
  }
}
