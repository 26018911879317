<!-- Header -->
<app-header></app-header>

<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4">
    <!-- <app-breadcrumbs title="Single Item - Buy Now" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->
</div>

<section class="container pb-md-4">
    <!-- Product -->
    <div class="bg-light shadow-lg rounded-3 px-4 py-lg-4 py-3 mb-5">
        <div class="py-lg-3 py-2 px-lg-3">
            <div class="row gy-4">
                <!-- QR Code PIX -->
                <div class="col-lg-6">
                    <p class="mb-4 pb-md-2 fs-sm">Complete o pagamento utilizando o QR Code abaixo.</p>
                    <div class="position-relative rounded-3 overflow-hidden mb-lg-4 mb-2">
                        <img [src]="qrCodeUrl" alt="QR Code PIX" class="img-fluid">
                    </div>
                    <div class="pt-2 text-lg-start text-center">
                        
                    </div>
                </div>

                <!-- Payment details -->
                <div class="col-lg-6">
                    <div class="ps-xl-5 ps-lg-3">
                        <!-- Meta -->
                        <!-- Comentado Minted on 29/10/2021 -->
                        <!-- Comentado 15 views e 4 favorites -->
                        <!-- <div class="d-flex align-items-center flex-wrap text-nowrap mb-sm-4 mb-3 fs-sm">
                            <div class="mb-2 me-sm-3 me-2 text-muted">Minted on {{ 'Oct 29, 2021' | date:'dd/MM/yyyy' }}</div>
                            <div class="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted">
                                <i class="ci-eye me-1 fs-base mt-n1 align-middle"></i> 15 views
                            </div>
                            <div class="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted">
                                <i class="ci-heart me-1 fs-base mt-n1 align-middle"></i> 4 favorites
                            </div>
                        </div> -->

                        <!-- QR Code Copia e Cola -->
                        <div class="mb-4 pb-md-2">
                            <h3 class="h6 mb-2 fs-sm text-muted">Código para Copia e Cola</h3>
                            <!-- Adicionando word-wrap e word-break para evitar a barra de rolagem -->
                            <pre class="bg-light p-3"
                                style="white-space: pre-wrap; word-wrap: break-word;">{{pixCode || 'Código de Copia e Cola não disponível'}}</pre>
                        </div>

                        <!-- Botão de copiar código (movido para substituir o botão de "Confirmar pagamento") -->
                        <div class="col">
                            <button class="btn btn-lg btn-accent d-block w-100" (click)="copyToClipboard()">Copiar
                                código</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Bg shape -->
<div class="pt-4 bg-secondary">
    
</div>

<app-footer></app-footer>