import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {
  private apiUrl = `${environment.apiUrl}/mercadopago`; // Using environment to define API URL

  constructor(private http: HttpClient) { }

  /**
   * Create a PIX payment
   * @param paymentData - The data for the PIX payment, typically including transaction amount, description, payer details, etc.
   * @returns An Observable with the payment creation result
   */
  createPixPayment(paymentData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/create-pix`, paymentData);
  }

  /**
   * Get the details of a payment by its ID
   * @param paymentId - The ID of the payment to check
   * @returns An Observable with the payment details
   */
  getPaymentDetails(paymentId: string): Observable<any> {
    // Adjusted the endpoint to /payment-details
    return this.http.get<any>(`${this.apiUrl}/payment-details/${paymentId}?fields=qr_code,status,id,qr_code_base64`);
  }

  /**
   * Webhook for PIX payment notifications
   * @param notificationData - Data sent by Mercado Pago to update the payment status
   * @returns An Observable that processes the webhook data
   */
  handlePixWebhook(notificationData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/pix-webhook`, notificationData);
  }
}