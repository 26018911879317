import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { RaffleService } from '../../../shared/services/raffle.service'; // Certifique-se de importar o serviço corretamente

@Component({
  selector: 'app-my-item',
  templateUrl: './my-item.component.html',
  styleUrls: ['./my-item.component.scss'],
  providers: [DatePipe]
})

export class MyItemComponent implements OnInit {

  myitems: any[] = [];
  liveitem: any[] = [];
  solditem: any[] = [];

  constructor(private raffleService: RaffleService) { }

  ngOnInit(): void {
    // Garantindo que a página vá para o topo
    document.documentElement.scrollTop = 0;

    // Ocultar elementos da conta e carteira
    document.querySelector('.account')?.classList.add('d-none');
    document.querySelector('.wallet')?.classList.add('d-none');
    document.querySelector('.connectwallet')?.classList.add('d-none');

    // Remove footer de inscrição por e-mail
    document.querySelector('.footer .bg-dark')?.classList.remove('mt-n10', 'pt-10');
    document.querySelector('.footer.bg-secondary')?.classList.add('d-none');

    // Busca de rifas
    this.raffleService.getAllRaffles().subscribe((raffles) => {
      this.myitems = raffles;
      this.liveitem = raffles.filter(item => item.status === 'closed'); // Encerradas
      this.solditem = raffles.filter(item => item.status === 'live'); // Em Andamento
    });
  }

  // Função para contar o tempo restante até o draw_date
  countdown(time: string): string {
    const now = new Date().getTime();
    const targetDate = new Date(time).getTime();
    const diff = targetDate - now;

    if (diff > 0) {
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    } else {
      return 'Encerrado'; // Alterado para "Encerrado"
    }
  }
}