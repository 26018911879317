import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RaffleService } from '../../../shared/services/raffle.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-createnewitem',
  templateUrl: './createnewitem.component.html',
  styleUrls: ['./createnewitem.component.scss']
})
export class CreatenewitemComponent implements OnInit {

  itemData!: UntypedFormGroup;
  submitted = false;
  selectedFiles: File[] = [];  // Adiciona campo para armazenar os arquivos selecionados
  previewImage: string | ArrayBuffer | null = null;  // Para armazenar a visualização da imagem carregada

  constructor(
    public formBuilder: UntypedFormBuilder,
    private raffleService: RaffleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Inicializar o formulário com validação
    this.itemData = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      quota_total: [0, [Validators.required, Validators.min(1)]],
      quota_value: [0, [Validators.required, Validators.min(1)]],
      draw_date: ['', [Validators.required]],
    });
  }

  /**
   * Função para lidar com a mudança de arquivo
   */
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      this.selectedFiles = Array.from(event.target.files);

      // Carregar a visualização da imagem
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  /**
   * Função para criar uma nova rifa
   */
  createitem() {
    this.submitted = true;

    if (this.itemData.invalid) {
      console.log('Formulário inválido');
      this.showFormErrors();
      return;
    }

    // Criar objeto FormData para enviar os dados corretamente
    const formData = new FormData();
    formData.append('title', this.itemData.get('title')?.value);
    formData.append('description', this.itemData.get('description')?.value);
    formData.append('quota_total', this.itemData.get('quota_total')?.value);
    formData.append('quota_value', this.itemData.get('quota_value')?.value);
    formData.append('draw_date', this.itemData.get('draw_date')?.value);

    // Adicionar as imagens ao FormData
    this.selectedFiles.forEach((file) => {
      formData.append('images', file);
    });

    // Enviar os dados para o serviço
    this.raffleService.createRaffle(formData).subscribe(
      (response) => {
        console.log('Rifa criada com sucesso:', response);
        this.router.navigate(['/myitem']);
      },
      (error) => {
        console.error('Erro ao criar rifa:', error);
      }
    );
  }

  /**
   * Função para mostrar erros de validação
   */
  showFormErrors() {
    const controls = this.itemData.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        const errors = controls[name].errors;
        if (errors?.['required']) {
          console.log(`- O campo '${name}' é obrigatório.`);
        }
        if (errors?.['min']) {
          console.log(`- O campo '${name}' deve ter um valor mínimo de ${errors['min'].min}.`);
        }
      }
    }
  }

  get form() {
    return this.itemData.controls;
  }
}
