import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RaffleService {

  private apiUrl = `${environment.apiUrl}/raffles`;

  constructor(private http: HttpClient) { }

  // Método para obter todas as rifas
  getAllRaffles(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl, { withCredentials: true });
  }

  // Método para obter uma rifa pelo slug
  getRaffleBySlug(slug: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${slug}`, { withCredentials: true });
  }

  // Método para obter rifas por criador
  getRafflesByCreator(creatorId: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/creator/${creatorId}`, { withCredentials: true });
  }

  // Método para criar uma nova rifa
  createRaffle(raffle: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl, raffle, { withCredentials: true });
  }
  
  // Método para deletar uma rifa
  deleteRaffle(raffleId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${raffleId}`, { withCredentials: true });
  }

  // Método para atualizar uma rifa
  updateRaffle(raffleId: string, raffle: FormData): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${raffleId}`, raffle, { withCredentials: true });
  }
}
