<!-- Header -->
<app-header></app-header>

<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4">
    <app-account-breadcrumbs></app-account-breadcrumbs>
</div>

<div class="container mb-5 pb-3">
    <div class="bg-light shadow-lg rounded-3 overflow-hidden">
        <div class="row">
            <!-- Sidebar -->
            <aside class="col-lg-3 pe-xl-5">
                <app-acount-sidemenu></app-acount-sidemenu>
            </aside>

            <!-- Content -->
            <section class="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
                    <div class="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-4">
                        <h1 class="h3 mb-sm-0 mb-2 pb-1 text-sm-start text-center">Minhas campanhas</h1>
                        <!-- Traduzido -->
                        <div class="pb-1" style="overflow-x: auto;">
                            <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-fill flex-nowrap text-nowrap mb-0">
                                <!-- Aba "Todos" -->
                                <li class="nav-item" ngbNavItem="All">
                                    <a ngbNavLink class="nav-link">
                                        Todos <!-- Traduzido -->
                                        <span class='opacity-70'>({{myitems.length}})</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <!-- Grid de Raffles (Todos) -->
                                        <!-- Exibe todas as rifas sem filtro -->
                                        <div class="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 gx-3 mb-4">
                                            <div *ngFor="let raffle of myitems" class="col mb-2">
                                                <article class="card h-100 border-0 shadow">
                                                    <div class="card-img-top position-relative overflow-hidden">
                                                        <a class="d-block">
                                                            <img src="assets/img/nft/catalog/02.jpg"
                                                                alt="Product image">
                                                        </a>
                                                        <!-- Countdown timer -->
                                                        <div *ngIf="raffle.draw_date"
                                                            class="badge bg-dark m-3 fs-sm position-absolute top-0 start-0 zindex-5">
                                                            <i class="ci-time me-1"></i>
                                                            <div class="countdown d-inline">
                                                                <span>{{ countdown(raffle.draw_date) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        <h3 class="product-title mb-2 fs-base">
                                                            <a class="d-block text-truncate">{{ raffle.title }}</a>
                                                        </h3>
                                                        <span class="fs-sm text-muted">Total de Cotas:</span>
                                                        <h4 class="mt-1 mb-0 fs-base text-darker">{{ raffle.quota_total
                                                            }}</h4>
                                                    </div>
                                                    <div class="card-footer mt-n1 py-0 border-0">
                                                        <!-- Exibe a data de sorteio (draw_date) no formato dd/MM/yyyy -->
                                                        <div
                                                            class="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                                            <a class="nav-link-style fs-sm stretched-link">
                                                                Data Final: {{ raffle.draw_date | date: 'dd/MM/yyyy' || 'Data não disponível' }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>

                                <!-- Aba "Em Andamento" -->
                                <li class="nav-item" ngbNavItem="sold">
                                    <a ngbNavLink class="nav-link">
                                        Em Andamento <!-- Alterado -->
                                        <span class='opacity-70'>({{solditem.length}})</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <!-- Rifas em andamento -->
                                        <!-- Exibe apenas as rifas em andamento (status 'live') -->
                                        <div class="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 gx-3 mb-4">
                                            <div *ngFor="let raffle of solditem" class="col mb-2">
                                                <article class="card h-100 border-0 shadow">
                                                    <div class="card-img-top position-relative overflow-hidden">
                                                        <a class="d-block">
                                                            <img src="assets/img/nft/catalog/02.jpg"
                                                                alt="Product image">
                                                        </a>
                                                        <!-- Countdown timer -->
                                                        <div *ngIf="raffle.draw_date"
                                                            class="badge bg-dark m-3 fs-sm position-absolute top-0 start-0 zindex-5">
                                                            <i class="ci-time me-1"></i>
                                                            <div class="countdown d-inline">
                                                                <span>{{ countdown(raffle.draw_date) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        <h3 class="product-title mb-2 fs-base">
                                                            <a class="d-block text-truncate">{{ raffle.title }}</a>
                                                        </h3>
                                                        <span class="fs-sm text-muted">Total de Cotas:</span>
                                                        <h4 class="mt-1 mb-0 fs-base text-darker">{{ raffle.quota_total
                                                            }}</h4>
                                                    </div>
                                                    <div class="card-footer mt-n1 py-0 border-0">
                                                        <!-- Exibe a data de sorteio (draw_date) no formato dd/MM/yyyy -->
                                                        <div
                                                            class="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                                            <a class="nav-link-style fs-sm stretched-link">
                                                                Data Encerramento: {{ raffle.draw_date | date: 'dd/MM/yyyy' || 'Data não disponível' }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>

                                <!-- Aba "Encerradas" -->
                                <li class="nav-item" ngbNavItem="live">
                                    <a ngbNavLink class="nav-link">
                                        Encerradas <!-- Alterado -->
                                        <span class='opacity-70'>({{liveitem.length}})</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <!-- Rifas encerradas -->
                                        <!-- Exibe apenas as rifas encerradas (status 'closed') -->
                                        <div class="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 gx-3 mb-4">
                                            <div *ngFor="let raffle of liveitem" class="col mb-2">
                                                <article class="card h-100 border-0 shadow">
                                                    <div class="card-img-top position-relative overflow-hidden">
                                                        <a class="d-block">
                                                            <img src="assets/img/nft/catalog/02.jpg"
                                                                alt="Product image">
                                                        </a>
                                                        <!-- Encerrado Badge -->
                                                        <div
                                                            class="badge bg-danger m-3 fs-sm position-absolute top-0 start-0 zindex-5">
                                                            <i class="ci-time me-1"></i> Encerrado
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        <h3 class="product-title mb-2 fs-base">
                                                            <a class="d-block text-truncate">{{ raffle.title }}</a>
                                                        </h3>
                                                        <span class="fs-sm text-muted">Total de Cotas:</span>
                                                        <h4 class="mt-1 mb-0 fs-base text-darker">{{ raffle.quota_total
                                                            }}</h4>
                                                    </div>
                                                    <div class="card-footer mt-n1 py-0 border-0">
                                                        <!-- Exibe a data de sorteio (draw_date) no formato dd/MM/yyyy -->
                                                        <div
                                                            class="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                                            <a class="nav-link-style fs-sm stretched-link">
                                                                Data Final: {{ raffle.draw_date | date: 'dd/MM/yyyy' || 'Data não disponível' }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div [ngbNavOutlet]="nav"></div>

                    <!-- Create new item -->
                    <div class="d-flex flex-sm-row flex-column">
                        <a class="btn btn-accent" routerLink="/createnewitem">
                            Criar nova campanha <!-- Traduzido -->
                        </a>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer></app-footer>
