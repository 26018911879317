<!-- Header -->
<app-header></app-header>
<!-- Page title-->
<div class="bg-accent mb-4 py-4">
    <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
                    <li class="breadcrumb-item"><a class="text-nowrap" routerLink="/"><i class="ci-home"></i>Home</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap"><a routerLink="/">NFT Marketplace</a>
                    </li>
                    <li class="breadcrumb-item text-nowrap active" aria-current="page">Connect Wallet</li>
                </ol>
            </nav>
        </div>
        <div class="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 class="h3 text-light mb-0">Connect Wallet</h1>
        </div>
    </div>
</div>
<!-- Page content-->
<section class="container mb-5 pt-1 pb-md-5 pb-4">
    <div class="mb-4 pb-3">
        <p class="mb-2 fs-lg">Choose the most suitable blockchain wallet for your needs.</p><a href="javascript:void(0);"><ins>Learn more
                about wallets</ins></a>
    </div>
    <!-- Payment items grid-->
    <div class="row row-cols-lg-3 row-cols-sm-2 row-cols-1 gy-sm-grid-gutter gy-3">
        <!-- Payment item-->
        <div class="col">
            <article class="card border-0 shadow position-relative h-100">
                <div class="card-body">
                    <div class="d-flex align-items-start justify-content-between mb-4"><img src="assets/img/nft/connect-wallet/metamask.svg" width="60" alt="Meta Mask"><span class="badge bg-success ms-3">Popular</span></div>
                    <h2 class="h5 mb-2"><a class="nav-link-style stretched-link" href="javascript:void(0);">Meta
                            Mask</a></h2>
                    <p class="mb-0 fs-sm text-muted">MetaMask provides an essential utility for blockchain newcomers,
                        token traders, crypto gamers and developers.</p>
                </div>
            </article>
        </div>
        <!-- Payment item-->
        <div class="col">
            <article class="card border-0 shadow position-relative h-100">
                <div class="card-body">
                    <div class="d-flex align-items-start justify-content-between mb-4"><img src="assets/img/nft/connect-wallet/coinbase.svg" width="60" alt="Coinbase"></div>
                    <h2 class="h5 mb-2"><a class="nav-link-style stretched-link" href="javascript:void(0);">Coinbase
                            Wallet</a></h2>
                    <p class="mb-0 fs-sm text-muted">Coinbase Wallet is your passport to the decentralized web. Harness
                        the power of DeFi to earn yield, grow your NFT collection, and much more.</p>
                </div>
            </article>
        </div>
        <!-- Payment item-->
        <div class="col">
            <article class="card border-0 shadow position-relative h-100">
                <div class="card-body">
                    <div class="d-flex align-items-start justify-content-between mb-4"><img src="assets/img/nft/connect-wallet/walletconnect.svg" width="60" alt="Wallet connect"></div>
                    <h2 class="h5 mb-2"><a class="nav-link-style stretched-link" href="javascript:void(0);">Wallet
                            Connect</a></h2>
                    <p class="mb-0 fs-sm text-muted">WalletConnect is the web3 standard to connect blockchain wallets to
                        dapps. Hundreds of dapp’s use WalletConnect to connect with wallets.</p>
                </div>
            </article>
        </div>
        <!-- Payment item-->
        <div class="col">
            <article class="card border-0 shadow position-relative h-100">
                <div class="card-body">
                    <div class="d-flex align-items-start justify-content-between mb-4"><img src="assets/img/nft/connect-wallet/fortmatic.svg" width="60" alt="Fortmatic"></div>
                    <h2 class="h5 mb-2"><a class="nav-link-style stretched-link" href="javascript:void(0);">Fortmatic</a></h2>
                    <p class="mb-0 fs-sm text-muted">Fortmatic is SOC 2 Type 1 compliant with a non-custodial HSM
                        architecture. Fortmatic is a collaborative effort from the best.</p>
                </div>
            </article>
        </div>
        <!-- Payment item-->
        <div class="col">
            <article class="card border-0 shadow position-relative h-100">
                <div class="card-body">
                    <div class="d-flex align-items-start justify-content-between mb-4"><img src="assets/img/nft/connect-wallet/portis.svg" width="60" alt="Portis"></div>
                    <h2 class="h5 mb-2"><a class="nav-link-style stretched-link" href="javascript:void(0);">Portis</a>
                    </h2>
                    <p class="mb-0 fs-sm text-muted">Portis is non-sustodial blockchain wallet that makes apps simple
                        for everyone. Boost conversions by accepting cards worldwide.</p>
                </div>
            </article>
        </div>
        <!-- Payment item-->
        <div class="col">
            <article class="card border-0 shadow position-relative h-100">
                <div class="card-body">
                    <div class="d-flex align-items-start justify-content-between mb-4"><img src="assets/img/nft/connect-wallet/torus.svg" width="60" alt="Torus"></div>
                    <h2 class="h5 mb-2"><a class="nav-link-style stretched-link" href="javascript:void(0);">Torus</a>
                    </h2>
                    <p class="mb-0 fs-sm text-muted">Torus is built and designed to be trustless, users' identities are
                        not controlled by any central authority. One-click login for Web 3.0.</p>
                </div>
            </article>
        </div>
    </div>
</section>
<!-- Footer -->
<app-footer></app-footer>