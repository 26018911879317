import { Component, OnInit } from '@angular/core';


//Data Get
import { drops, trending, creators, collections, article } from './data';
import { favorite } from '../account/favorite/data';

// Swiper Slider
// import { SwiperOptions } from 'swiper';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

// Home Component
export class HomeComponent implements OnInit {

  recent_drop: any;
  trendingproduct: any;
  topcreators: any;
  collections: any;
  articles: any;
  selectedcategory: any = 'All categories';

  // set the current year
  year: number = new Date().getFullYear();
  private _diff?: any;
  _days?: number;
  _hours?: number;
  _minutes?: number;
  _seconds?: number;

  private diff?: any;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  timer: any;

  constructor() { }

  ngOnInit(): void {

    // When the user clicks on the button, scroll to the top of the document
    document.documentElement.scrollTop = 0;

    document.querySelector('header')?.classList.add('position-absolute')
    document.querySelector('header')?.classList.remove('bg-light')
    // Remove header user profile and create button
    document.querySelector('.user')?.classList.add('d-none')
    document.querySelector('.create')?.classList.add('d-none')
    document.querySelector('.craeteitem')?.classList.add('d-none')

    //Fetch Data
    this.recent_drop = drops
    this.trendingproduct = trending
    this.topcreators = creators
    this.collections = collections
    this.articles = article

  }

  /**
    * Count date set
    */
  countdown(time: any) {

    if(Date.parse(time) > Date.parse(new Date().toString())){
      this._diff = Date.parse(time) - Date.parse(new Date().toString());
      this._days = Math.floor(this._diff / (1000 * 60 * 60 * 24));
      this._hours = Math.floor((this._diff / (1000 * 60 * 60)) % 24);
      this._minutes = Math.floor((this._diff / 1000 / 60) % 60);
      this._seconds = Math.floor((this._diff / 1000) % 60);
      return ((this._hours < 10) ? '0' + this._hours : this._hours) + ':' + ((this._minutes < 10) ? '0' + this._minutes : this._minutes) + ':' + ((this._seconds < 10) ? '0' + this._seconds : this._seconds)
    }else{
      return '00:00:00'
    }
  }



  trendingcountdown(time: any) {
    if(Date.parse(time) > Date.parse(new Date().toString())){
      this._diff = Date.parse(time) - Date.parse(new Date().toString());
      this._days = Math.floor(this._diff / (1000 * 60 * 60 * 24));
      this._hours = Math.floor((this._diff / (1000 * 60 * 60)) % 24);
      this._minutes = Math.floor((this._diff / 1000 / 60) % 60);
      this._seconds = Math.floor((this._diff / 1000) % 60);
      return ((this._hours < 10) ? '0' + this._hours : this._hours) + ':' + ((this._minutes < 10) ? '0' + this._minutes : this._minutes) + ':' + ((this._seconds < 10) ? '0' + this._seconds : this._seconds)
    }else{
      return '00:00:00'
    }
  }

  /**
   * Swiper setting
   */
  config = {
    slidesToShow: 1, // Equivalent to slidesPerView: 1 in Swiper
    infinite: true, // Equivalent to loop: true in Swiper
    dots: true, // Equivalent to pagination: { el: '.swiper-pagination', clickable: true } in Swiper
    arrows: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  /**
   * Recent Drop Swiper setting
   */
  Drops = {
    slidesToShow: 4, // Equivalent to slidesPerView: 1 in Swiper
    infinite: true, // Equivalent to loop: true in Swiper
    arrows: true, // Equivalent to navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' } in Swiper
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  /**
   * creator Swiper setting
   */
  Creator = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 3,
          rows: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 3,
          rows: 3,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          slidesPerRow: 3,
          rows: 3,
        },
      },
    ],
  };

  /**
   * Collection Swiper setting
   */
  Collection = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //set category
  setcategory(ev: any) {
    this.selectedcategory = ev.target.closest('span')?.innerHTML
  }

  // Add Drops Favorite
  dropfavorite(id: any) {
    this.recent_drop[id].is_like = '1'
    favorite.push(this.recent_drop[id])
  }

  // Add Trending Favorite
  trendfavorite(id: any) {
    this.trendingproduct[id].is_like = '1'
    favorite.push(this.trendingproduct[id])
  }

}
