const drops = [
    {
        id: '1',
        image: 'assets/img/nft/catalog/01.jpg',
        title: '3d aesthetics with shapes',
        currentbid: '0.156 ETH',
        bid: '(≈ $ 595.76)',
        profile: 'assets/img/nft/catalog/avatars/01.png',
        name: 'foxnet_creator',
        time: '12/31/2022 12:00:00 PM'
    },
    {
        id: '2',
        image: 'assets/img/nft/catalog/02.jpg',
        title: 'Ocean and sky',
        currentbid: '0.5 ETH',
        bid: '(≈ $ 2,000.55)',
        profile: 'assets/img/nft/catalog/avatars/02.png',
        name: 'YunusKullebi',
        time: '12/31/2022 09:00:00 PM'
    },
    {
        id: '3',
        image: 'assets/img/nft/catalog/03.jpg',
        title: 'Aesthetic art collage',
        currentbid: '0.6 ETH',
        bid: '(≈ $ 2,400.65)',
        profile: 'assets/img/nft/catalog/avatars/03.png',
        name: 'lulucollages'
    },
    {
        id: '4',
        image: 'assets/img/nft/catalog/04.jpg',
        title: 'Astronaut surrounded by lights',
        currentbid: '0.1 ETH',
        bid: '(≈ $ 400.19)',
        profile: 'assets/img/nft/catalog/avatars/04.png',
        name: 'DistroKid'
    },
    {
        id: '5',
        image: 'assets/img/nft/catalog/09.jpg',
        title: '3d aesthetics with shapes',
        currentbid: '0.156 ETH',
        bid: '(≈ $ 595.76)',
        profile: 'assets/img/nft/catalog/avatars/09.png',
        name: 'shubham_dhage'
    },
    {
        id: '6',
        image: 'assets/img/nft/catalog/10.jpg',
        title: 'Ocean and sky',
        currentbid: '0.5 ETH',
        bid: '(≈ $ 2,000.55)',
        profile: 'assets/img/nft/catalog/avatars/10.png',
        name: 'MihailGreen'
    },
    {
        id: '7',
        image: 'assets/img/nft/catalog/11.jpg',
        title: 'Aesthetic art collage',
        currentbid: '0.6 ETH',
        bid: '(≈ $ 2,400.65)',
        profile: 'assets/img/nft/catalog/avatars/11.png',
        name: 'lulucollages'
    },
    {
        id: '8',
        image: 'assets/img/nft/catalog/12.jpg',
        title: 'Astronaut surrounded by lights',
        currentbid: '0.1 ETH',
        bid: '(≈ $ 400.19)',
        profile: 'assets/img/nft/catalog/avatars/12.png',
        name: 'Sharan_Pagadala'
    }
]

const trending = [
    {
        id: '1',
        title: 'Aesthetic art collage',
        image: 'assets/img/nft/catalog/05.jpg',
        price: '0.6 ETH',
        dollorprice: '(≈ $ 2,400.65)',
        profile: 'assets/img/nft/catalog/avatars/05.png',
        name: 'Sharan_Pagadala'
    },
    {
        id: '2',
        title: 'Ocean and sky',
        image: 'assets/img/nft/catalog/06.jpg',
        price: '0.5 ETH',
        dollorprice: '(≈ $ 2,000.55)',
        profile: 'assets/img/nft/catalog/avatars/06.png',
        name: 'Simonlee',
        time: '12/31/2022 12:00:00 PM'
    },
    {
        id: '3',
        title: '3d aesthetics with shapes',
        image: 'assets/img/nft/catalog/07.jpg',
        price: '0.156 ETH',
        dollorprice: '(≈ $ 595.76)',
        profile: 'assets/img/nft/catalog/avatars/07.png',
        name: 'Shubham_Dhage',
        time: '12/31/2022 12:00:00 PM'
    },
    {
        id: '4',
        title: 'Astronaut surrounded by lights',
        image: 'assets/img/nft/catalog/08.jpg',
        price: '0.1 ETH',
        dollorprice: '(≈ $ 400.19)',
        profile: 'assets/img/nft/catalog/avatars/08.png',
        name: 'DistroKid'
    }
]

const creators = [
    {
        id: '1',
        profile: 'assets/img/nft/home/creators/01.png',
        name: 'freeross',
        follower: '589'
    },
    {
        id: '2',
        profile: 'assets/img/nft/home/creators/02.png',
        name: 'Sharan_Pagadala',
        follower: '84'
    },
    {
        id: '3',
        profile: 'assets/img/nft/home/creators/03.png',
        name: 'Simonlee',
        follower: '136'
    },
    {
        id: '4',
        profile: 'assets/img/nft/home/creators/04.png',
        name: 'annet_creator',
        follower: '83'
    },
    {
        id: '5',
        profile: 'assets/img/nft/home/creators/05.png',
        name: 'shubham_dhage',
        follower: '105'
    },
    {
        id: '6',
        profile: 'assets/img/nft/home/creators/06.png',
        name: 'distrokid',
        follower: '902'
    },
    {
        id: '7',
        profile: 'assets/img/nft/home/creators/07.png',
        name: '42Labs',
        follower: '730'
    },
    {
        id: '8',
        profile: 'assets/img/nft/home/creators/08.png',
        name: 'ZeniconStudio',
        follower: '299'
    },
    {
        id: '9',
        profile: 'assets/img/nft/home/creators/09.png',
        name: 'MihailGreen',
        follower: '100'
    }
]

const collections = [
    {
        id: '1',
        title: 'Contemporary art collage',
        image: ['assets/img/nft/collections/1-1.jpg', 'assets/img/nft/collections/1-2.jpg', 'assets/img/nft/collections/1-3.jpg'],
        price: '12180.95',
        by: 'Sharan_Pagadala',
        profile: 'assets/img/nft/thumbnails/01.png'
    },
    {
        id: '2',
        title: '3D digital abstract art',
        image: ['assets/img/nft/collections/2-1.jpg', 'assets/img/nft/collections/2-2.jpg', 'assets/img/nft/collections/2-3.jpg'],
        price: '9020.34',
        by: 'Simonlee',
        profile: 'assets/img/nft/thumbnails/02.png'
    },
    {
        id: '3',
        title: 'Clone X Mini Monsters',
        image: ['assets/img/nft/collections/3-1.jpg', 'assets/img/nft/collections/3-2.jpg', 'assets/img/nft/collections/3-3.jpg'],
        price: '1520.18',
        by: 'Annet_creator',
        profile: 'assets/img/nft/thumbnails/03.png'
    },
]


const article = [
    {
        id: '1',
        image: 'assets/img/nft/blog/01.jpg',
        title: 'The complete guide to NFTs art for creators and investors',
        by: 'Wade Warren',
        date: 'Aug 15'
    },
    {
        id: '2',
        image: 'assets/img/nft/blog/02.jpg',
        title: 'A chance to win a variety of common, rare and unique NFTs',
        by: 'Kathryn Murphy',
        date: 'Sep 18'
    },
    {
        id: '3',
        image: 'assets/img/nft/blog/03.jpg',
        title: 'Exclusive premium events, from exhibitions to unique collectibles',
        by: 'Devon Lane',
        date: 'Nov 26'
    },
    {
        id: '1',
        image: 'assets/img/nft/blog/01.jpg',
        title: 'The complete guide to NFTs art for creators and investors',
        by: 'Wade Warren',
        date: 'Aug 15'
    },
    {
        id: '2',
        image: 'assets/img/nft/blog/02.jpg',
        title: 'A chance to win a variety of common, rare and unique NFTs',
        by: 'Kathryn Murphy',
        date: 'Sep 18'
    },
    {
        id: '3',
        image: 'assets/img/nft/blog/03.jpg',
        title: 'Exclusive premium events, from exhibitions to unique collectibles',
        by: 'Devon Lane',
        date: 'Nov 26'
    },
    {
        id: '1',
        image: 'assets/img/nft/blog/01.jpg',
        title: 'The complete guide to NFTs art for creators and investors',
        by: 'Wade Warren',
        date: 'Aug 15'
    },
    {
        id: '2',
        image: 'assets/img/nft/blog/02.jpg',
        title: 'A chance to win a variety of common, rare and unique NFTs',
        by: 'Kathryn Murphy',
        date: 'Sep 18'
    },
    {
        id: '3',
        image: 'assets/img/nft/blog/03.jpg',
        title: 'Exclusive premium events, from exhibitions to unique collectibles',
        by: 'Devon Lane',
        date: 'Nov 26'
    }
]

export { drops, trending, creators, collections, article }