<!-- Header -->
<app-header></app-header>
<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4">
    <!-- <app-breadcrumbs title="Título" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs> -->
</div>

<section class="container pb-md-4">
    <!-- Product -->
    <div class="bg-light shadow-lg rounded-3 px-4 py-lg-4 py-3 mb-5">
        <div class="py-lg-3 py-2 px-lg-3">
            <div class="row gy-4">
                <!-- Product image -->
                <div class="col-lg-6">
                    <div *ngIf="raffleData?.images?.length > 0">
                        <!-- Carousel for images -->
                        <div id="imageCarousel" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-inner">
                                <div *ngFor="let imageUrl of raffleData.images; let i = index" class="carousel-item"
                                    [ngClass]="{'active': i === 0}">
                                    <lib-ngx-image-zoom [thumbImage]="imageUrl" [fullImage]="imageUrl"
                                        [magnification]="1" [enableScrollZoom]="true" [lensWidth]="500"
                                        [lensHeight]="500">
                                    </lib-ngx-image-zoom>
                                </div>
                            </div>
                            <!-- Carousel controls -->
                            <button class="carousel-control-prev" type="button" data-bs-target="#imageCarousel"
                                data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#imageCarousel"
                                data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>
                    <div class="pt-2 text-lg-start text-center">
                        <button class="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2" type="button"
                            data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Add to Favorites">
                            <i class="ci-heart"></i>
                        </button>
                        <button class="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2" type="button"
                            data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Share">
                            <i class="ci-share-alt"></i>
                        </button>
                        <button class="btn btn-secondary rounded-pill btn-icon me-sm-3 me-2" type="button"
                            data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Flag">
                            <i class="ci-flag"></i>
                        </button>
                    </div>
                </div>
                <!-- Product details -->
                <div class="col-lg-6">
                    <div class="ps-xl-5 ps-lg-3">
                        <!-- Meta -->
                        <h2 class="h3 mb-3">{{raffleData?.title || 'Título'}}</h2>

                        <!-- Comentado -->
                        <!-- <div class="d-flex align-items-center flex-wrap text-nowrap mb-sm-4 mb-3 fs-sm">
                            <div class="mb-2 me-sm-3 me-2 text-muted">
                                Data do Sorteio: {{raffleData?.draw_date | date:'dd \'de\' MMMM \'de\' yyyy'}}
                            </div>
                            <div class="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted">
                                <i class="ci-eye me-1 fs-base mt-n1 align-middle"></i>
                                15 views
                            </div>
                            <div class="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted">
                                <i class="ci-heart me-1 fs-base mt-n1 align-middle"></i>
                                4 favorite
                            </div>
                        </div> -->

                        <!-- Description -->
                        <p class="mb-4 pb-md-2 fs-sm">
                            {{raffleData?.description || 'Descrição padrão aqui...'}}
                        </p>

                        <!-- Auction -->
                        <div class="row row-cols-sm-2 row-cols-1 gy-3 mb-4 pb-md-2">
                            <div class="col">
                                <h3 class="h6 mb-2 fs-sm text-muted">Preço por número</h3>
                                <h2 class="h3 mb-1">R$ {{raffleData?.quota_value || '0.00'}}</h2>
                            </div>
                            <div class="col">
                                <h3 class="h6 mb-2 pb-1 fs-sm text-muted"> Sorteio em</h3>
                                <div class="countdown h6 mb-0">
                                    <div *ngIf="days" class="countdown-days bg-dark text-light rounded p-2">
                                        <span class="countdown-value">{{days}} d</span>
                                    </div>
                                    <div *ngIf="hours" class="countdown-hours bg-dark text-light rounded p-2">
                                        <span class="countdown-value">{{hours}} h</span>
                                    </div>
                                    <div *ngIf="minutes" class="countdown-minutes bg-dark text-light rounded p-2">
                                        <span class="countdown-value">{{minutes}} m</span>
                                    </div>
                                    <div *ngIf="seconds" class="countdown-seconds bg-dark text-light rounded p-2">
                                        <span class="countdown-value">{{seconds}} s</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Formulário para captura de informações de bid -->
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <label for="contact" class="form-label">Seu Contato (Email ou Telefone)</label>
                                <input type="text" id="contact" [(ngModel)]="contact" class="form-control"
                                    placeholder="Insira seu contato">
                            </div>
                            <div class="col-md-6">
                                <label for="quantity" class="form-label">Quantidade de Cotas</label>
                                <input type="number" id="quantity" [(ngModel)]="quantity" class="form-control" min="1"
                                    max="10">
                            </div>
                        </div>

                        <!-- Comprar número -->
                        <a class="btn btn-lg btn-accent d-block w-100 mb-4" href="javascript:void(0);"
                            (click)="placeBid()">
                            Comprar número
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Recent products -->

<!-- Bg shape-->
<div class="pt-4 bg-secondary">
    <!-- Features-->
</div>

<app-footer></app-footer>