<!-- Header -->
<app-header></app-header>
<!-- Page Title-->
<div class="bg-accent pt-4 pb-5">
    <app-breadcrumbs title="Catalog v.2" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
</div>

<div class="container pb-5 mb-2 mb-md-4">
    <!-- Toolbar-->
    <div class="bg-light shadow-lg rounded-3 mb-4" style="margin-top: -35px;">
        <div class="d-flex">
            <!-- Search-->
            <div class="w-100">
                <div class="input-group ms-2 pe-sm-3">
                    <input class="form-control bg-transparent rounded-0 border-0 shadow-none ps-5 py-4" type="text" placeholder="Search collection, title or user..." [(ngModel)]="service.searchTerm"><i class="ci-search position-absolute top-50 start-0 translate-middle-y zindex-5 ms-3 fs-lg text-muted"></i>
                </div>
            </div>
            <!-- Sorting-->
            <div class="flex-shrink-0 border-start">
                <div ngbDropdown class="dropdown">
                    <button ngbDropdownToggle class="btn border-0 p-4 fw-medium" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="d-sm-inline d-none">Recently
                            added</span>
                        <!-- <i class="ci-arrow-down ms-sm-2 fs-sm"></i> -->
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu w-100"><a class="dropdown-item active" href="javascript:void(0);" style="white-space: normal;">Recently added</a><a class="dropdown-item" href="javascript:void(0);" style="white-space: normal;">Oldest</a>
                    </div>
                </div>
            </div>
            <div class="d-md-flex d-none">
                <!-- Pages-->
                <div class="border-start">
                    <div class="p-4 fs-md text-nowrap">Pages 1/5</div>
                </div>
                <!-- Back-->
                <div class="border-start"><a class="btn border-0 p-4 fw-medium" href="javascript:void(0);"><i class="ci-arrow-left"></i></a></div>
                <!-- Forward-->
                <div class="border-start"><a class="btn border-0 p-4 fw-medium" href="javascript:void(0);"><i class="ci-arrow-right"></i></a></div>
            </div>
        </div>
    </div>
    <!-- Filters-->
    <div class="d-flex flex-lg-row flex-column align-items-lg-center justify-content-between pt-lg-3">
        <div class="d-flex flex-wrap">
            <!-- Categories-->
            <div ngbDropdown class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
                <button ngbDropdownToggle class="btn btn-outline-accent dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">Categories</button>
                <div ngbDropdownMenu class="dropdown-menu mt-lg-2 mt-1" style="min-width: 260px;">
                    <div class="widget px-3">
                        <ul class="widget-list list-unstyled">
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="all">
                                    <label class="form-check-label" for="all">All artworks</label>
                                </div><span class="fs-xs text-muted">498</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="premium">
                                    <label class="form-check-label" for="premium">Premium</label>
                                </div><span class="fs-xs text-muted">25</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="art">
                                    <label class="form-check-label" for="art">Art</label>
                                </div><span class="fs-xs text-muted">112</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="photography">
                                    <label class="form-check-label" for="photography">Photography</label>
                                </div><span class="fs-xs text-muted">93</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="music">
                                    <label class="form-check-label" for="music">Music</label>
                                </div><span class="fs-xs text-muted">48</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="gaming">
                                    <label class="form-check-label" for="gaming">Gaming</label>
                                </div><span class="fs-xs text-muted">82</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="sports">
                                    <label class="form-check-label" for="sports">Sports</label>
                                </div><span class="fs-xs text-muted">51</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="collections">
                                    <label class="form-check-label" for="collections">Collections</label>
                                </div><span class="fs-xs text-muted">11</span>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="utility">
                                    <label class="form-check-label" for="utility">Utility</label>
                                </div><span class="fs-xs text-muted">24</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Sale Types-->
            <div ngbDropdown class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
                <button ngbDropdownToggle class="btn btn-outline-accent dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">Sale Types</button>
                <div ngbDropdownMenu class="dropdown-menu mt-lg-2 mt-1" style="min-width: 260px;">
                    <div class="widget px-3">
                        <ul class="widget-list list-unstyled">
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" [(ngModel)]="fixed" value="fixed price" type="checkbox" id="fixed-price" (change)="typefilter($event)">
                                    <label class="form-check-label" for="fixed-price">Fixed Price</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" [(ngModel)]="live" value="live-auction" type="checkbox" id="live-auction" (change)="typefilter($event)">
                                    <label class="form-check-label" for="live-auction">Live auction</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Price range-->
            <div ngbDropdown class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
                <button ngbDropdownToggle class="btn btn-outline-accent dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">Price range</button>
                <div ngbDropdownMenu class="dropdown-menu mt-lg-2 mt-1 p-3" style="min-width: 260px;">
                    <form>
                        <select class="form-select form-select-sm mb-3">
                            <option selected disabled>ETH</option>
                            <option>BNB</option>
                            <option>BUSD</option>
                        </select>
                        <div class="d-flex align-items-center">
                            <div class="w-50">
                                <input class="form-control form-control-sm" type="text" placeholder="From">
                            </div><span class="d-inline-block mx-2">–</span>
                            <div class="w-50">
                                <input class="form-control form-control-sm" type="text" placeholder="To">
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Collections-->
            <div ngbDropdown class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
                <button ngbDropdownToggle class="btn btn-outline-accent dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">Collections</button>
                <div ngbDropdownMenu class="dropdown-menu mt-lg-2 mt-1" style="min-width: 260px;">
                    <div class="widget px-3">
                        <ul class="widget-list list-unstyled">
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="contemporary-art-collage" value="Contemporary art collage" (change)="colllectionchane($event)">
                                    <label class="form-check-label" for="contemporary-art-collage">Contemporary art
                                        collage</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="3d-digital-abstract-art" value="3D digital abstract" (change)="colllectionchane($event)">
                                    <label class="form-check-label" for="3d-digital-abstract-art">3D digital abstract
                                        art</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="clone-x-mini-monsters" value="Clone X Mini Monsters" (change)="colllectionchane($event)">
                                    <label class="form-check-label" for="clone-x-mini-monsters">Clone X Mini
                                        Monsters</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="ocean-and-sky" value="Ocean and sky" (change)="colllectionchane($event)">
                                    <label class="form-check-label" for="ocean-and-sky">Ocean and sky</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="aesthetic-art-collage" value="Aesthetic art collage" (change)="colllectionchane($event)">
                                    <label class="form-check-label" for="aesthetic-art-collage">Aesthetic art
                                        collage</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Creators-->
            <div ngbDropdown class="dropdown mb-lg-3 mb-2 me-lg-3 me-2">
                <button ngbDropdownToggle class="btn btn-outline-accent dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">Creators</button>
                <div ngbDropdownMenu class="dropdown-menu mt-lg-2 mt-1" style="min-width: 260px;">
                    <div class="widget px-3">
                        <ul class="widget-list list-unstyled">
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="foxnet_creator" value="foxnet_creator" (change)="creatorfilter($event)">
                                    <label class="form-check-label" for="foxnet_creator">&#64;foxnet_creator</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="YunusKullebi" value="YunusKullebi" (change)="creatorfilter($event)">
                                    <label class="form-check-label" for="YunusKullebi">&#64;YunusKullebi</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="lulucollages" value="lulucollages" (change)="creatorfilter($event)">
                                    <label class="form-check-label" for="lulucollages">&#64;lulucollages</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="DistroKid" value="DistroKid" (change)="creatorfilter($event)">
                                    <label class="form-check-label" for="DistroKid">&#64;DistroKid</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="Sharan_Pagadala" value="Sharan_Pagadala" (change)="creatorfilter($event)">
                                    <label class="form-check-label" for="Sharan_Pagadala">&#64;Sharan_Pagadala</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="Simonlee" value="Simonlee" (change)="creatorfilter($event)">
                                    <label class="form-check-label" for="Simonlee">&#64;Simonlee</label>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between align-items-center mb-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="Shubham_Dhage" value="Simonlee" (change)="creatorfilter($event)">
                                    <label class="form-check-label" for="Shubham_Dhage">&#64;Shubham_Dhage</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Total-->
        <div class="mb-3 fs-sm text-muted">{{allproduct?.length}} results</div>
    </div>
    <!-- Products grid-->
    <div class="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 pt-lg-4 pt-2">
        <!-- Product-->
        @for(product of allproduct;track $index){
        <div class="col mb-2">
            <article class="card h-100 border-0 shadow">
                <div class="card-img-top position-relative overflow-hidden"><a class="d-block" routerLink="/auctionlive"><img src="{{product.image}}" alt="Product image"></a>
                    <!-- Countdown timer-->
                    @if(product.time){
                    <div class="badge bg-dark m-3 fs-sm position-absolute top-0 start-0 zindex-5">
                        <i class="ci-time me-1"></i>
                        <div class="countdown d-inline">
                            <span>{{countdown(product.time)}}</span>
                        </div>
                    </div>
                    }
                    <!-- Wishlist button-->
                    <button class="btn-wishlist btn-sm position-absolute top-0 end-0" type="button" data-bs-toggle="tooltip" data-bs-placement="left" ngbTooltip="Add to Favorites" style="margin: 12px;" (click)="addfavorite($index)">
                        <i [ngClass]="(product.is_like == '1')?'ci-heart-filled':'ci-heart'"></i></button>
                </div>
                <div class="card-body">
                    <h3 class="product-title mb-2 fs-base"><a class="d-block text-truncate" routerLink="/auctionlive">{{product.title}}</a></h3> <span class="fs-sm text-muted">
                        {{product.currentbid? 'Current bid:' : 'Reserve price:'}}</span>
                    <div class="d-flex align-items-center flex-wrap">
                        <h4 class="mt-1 mb-0 fs-base text-darker">
                            {{product.currentbid?product.currentbid:product.price}}</h4><span class="mt-1 ms-1 fs-xs text-muted">{{(product.bid)?product.bid:product.dollorprice}}</span>
                    </div>
                </div>
                <div class="card-footer mt-n1 py-0 border-0">
                    <div class="d-flex align-items-center position-relative mb-1 py-3 border-top"><img class="me-2 rounded-circle" src="{{product.profile}}" width="32" alt="Avatar"><a class="nav-link-style fs-sm stretched-link" routerLink="/vendor">&#64;{{product.name}}</a>
                    </div>
                </div>
            </article>
        </div>
    }
    </div>
    <div class="text-center" id="elmLoader">
        <div class="spinner-border text-primary avatar-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <hr class="mt-4 mb-3">
    <!-- Pagination -->
    <ngb-pagination class="d-flex justify-content-center pt-2" [collectionSize]="(total | async)!" [(page)]="service.page" [pageSize]="service.pageSize" aria-label="Custom pagination">
        <ng-template ngbPaginationPrevious let-page let-pages="pages">
            <i class="ci-arrow-left me-2"></i>
            Prev
        </ng-template>
        <ng-template ngbPaginationNext>
            Next
            <i class="ci-arrow-right ms-2"></i>
        </ng-template>
    </ngb-pagination>
</div>
<!-- Bg shape-->
<div class="pt-4 bg-secondary">
    <!-- Features-->
    <app-mailfooter></app-mailfooter>
</div>
<app-footer></app-footer>