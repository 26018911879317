import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user.model';

@Component({
  selector: 'app-account-breadcrumbs',
  templateUrl: './account-breadcrumbs.component.html',
  styleUrls: ['./account-breadcrumbs.component.scss']
})

// AccountBreadcrumbs Component
export class AccountBreadcrumbsComponent implements OnInit {

  userName: string = 'Usuário';  // Nome padrão caso o full_name seja undefined

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    // Obtenha os dados do usuário a partir do serviço de autenticação
    this.authService.getUserProfile().subscribe((user: User) => {
      this.userName = user.full_name || 'Usuário';  // Se full_name for undefined, define como 'Usuário'
    }, error => {
      console.error('Erro ao carregar dados do usuário', error);
    });
  }
}