<!-- Header -->
<app-header></app-header>

<!-- Page title-->
<div class="bg-accent mb-4 py-4">
    <div class="container">
        <h1 class="h3 text-light mb-0 text-center">Criar Nova Rifa</h1>
    </div>
</div>

<!-- Page content-->
<section class="container mb-5">
    <div class="row">
        <form class="col-md-8" (ngSubmit)="createitem()" [formGroup]="itemData" novalidate
            enctype="multipart/form-data">

            <!-- Upload de imagem (Substituindo o campo de URL por upload de arquivo) -->
            <div class="mb-4">
                <h2 class="h5 mb-2">Upload de Imagem</h2>
                <p>Tipos de arquivos suportados: PNG, JPG, GIF. Tamanho máximo: 5MB.</p>
                <div class="file-drop-area" style="border: 2px dashed #ccc; padding: 20px; text-align: center;">
                    <input type="file" class="form-control" (change)="onFileChange($event)" multiple>
                    <span class="file-drop-message">Arraste e solte arquivos aqui ou clique para selecionar</span>
                </div>
            </div>

            <!-- Detalhes da Rifa -->
            <div class="mb-4">
                <h2 class="h5 mb-3">Detalhes da Rifa</h2>
                <div class="row">
                    <div class="col-12">
                        <label class="form-label" for="title">Título<span class="ms-1 text-danger">*</span></label>
                        <input class="form-control" id="title" type="text" formControlName="title"
                            placeholder="Título da rifa" required>
                        <div class="invalid-feedback">Por favor, insira o título.</div>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="form-label" for="description">Descrição<span
                                class="ms-1 text-danger">*</span></label>
                        <textarea class="form-control" id="description" rows="4" formControlName="description"
                            placeholder="Descrição curta da rifa"></textarea>
                        <div class="invalid-feedback">Por favor, insira a descrição.</div>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="form-label" for="quota_total">Total de Cotas<span
                                class="ms-1 text-danger">*</span></label>
                        <input class="form-control" id="quota_total" type="number" formControlName="quota_total"
                            placeholder="Número total de cotas" required>
                        <div class="invalid-feedback">Por favor, insira o número total de cotas.</div>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="form-label" for="quota_value">Valor da Cota<span
                                class="ms-1 text-danger">*</span></label>
                        <input class="form-control" id="quota_value" type="number" formControlName="quota_value"
                            placeholder="Valor de cada cota" required>
                        <div class="invalid-feedback">Por favor, insira o valor de cada cota.</div>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="form-label" for="draw_date">Data do Sorteio<span
                                class="ms-1 text-danger">*</span></label>
                        <input class="form-control" id="draw_date" type="date" formControlName="draw_date" required>
                        <div class="invalid-feedback">Por favor, insira a data do sorteio.</div>
                    </div>
                </div>
            </div>

            <!-- Botão de Submissão -->
            <div class="pt-2">
                <button class="btn btn-lg btn-accent d-block w-100" type="submit">Criar Rifa</button>
            </div>
        </form>

        <!-- Preview da Imagem (dinâmico ou estático) -->
        <aside class="col-lg-4">
            <div class="sticky-top">
                <h4 class="h5 mb-3 text-center">Preview</h4>
                <div class="card border-0 shadow">
                    <div class="card-img-top">
                        <!-- Mostrar a imagem carregada ou uma imagem padrão -->
                        <img [src]="previewImage || 'assets/img/nft/catalog/06.jpg'" alt="Preview da Imagem"
                            class="img-fluid">
                    </div>
                    <div class="card-body">
                        <h3 class="product-title mb-2 text-center">{{ itemData.get('title')?.value || 'Título da Rifa'
                            }}</h3>
                        <p class="text-muted text-center">{{ itemData.get('description')?.value || 'Descrição da Rifa'
                            }}</p>
                    </div>
                </div>
            </div>
        </aside>
    </div>
</section>

<!-- Footer -->
<app-footer></app-footer>