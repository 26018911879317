import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Quota } from '../models/quota.model';
import { environment } from '../../../environments/environment'; // Importando o environment

@Injectable({
  providedIn: 'root'
})
export class QuotaService {
  private apiUrl = `${environment.apiUrl}/quotas`;

  constructor(private http: HttpClient) { }

  // Método para criar uma nova quota
  createQuota(quota: Omit<Quota, '_id'>): Observable<Quota> {
    return this.http.post<Quota>(`${this.apiUrl}`, quota);
  }

  // Método para buscar uma quota por ID
  getQuotaById(id: string): Observable<Quota> {
    return this.http.get<Quota>(`${this.apiUrl}/${id}`);
  }

  // Método para buscar quotas por contato (e-mail ou telefone)
  getQuotaByContact(contact: string): Observable<Quota[]> {
    return this.http.get<Quota[]>(`${this.apiUrl}/contact/${contact}`);
  }
}