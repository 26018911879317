<!-- Header -->
<app-header></app-header>
<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4">
    <app-account-breadcrumbs></app-account-breadcrumbs>
</div>
<div class="container mb-5 pb-3">
    <div class="bg-light shadow-lg rounded-3 overflow-hidden">
        <div class="row">
            <!-- Sidebar -->
            <aside class="col-lg-3 pe-xl-5">
                <app-acount-sidemenu></app-acount-sidemenu>
            </aside>
            <!-- Content -->
            <section class="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
                    <h1 class="h3 mb-4 pb-2 text-sm-start text-center">Notifications</h1>

                    <div class="bg-secondary rounded-3 p-4">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="nf-disable-all" value="option"
                                [(ngModel)]="masterSelected" (change)="checkUncheckAll($event)">
                            <label class="form-check-label fw-medium" for="nf-disable-all">
                                Enable/disable all
                                notifications
                            </label>
                        </div>
                    </div>
                    <div id="notification-settings">
                        @for(notify of allnotification;track $index){
                        <div class="border-bottom p-4">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="nf-product-sold"
                                    [(ngModel)]="notify.state">
                                <label class="form-check-label" for="nf-product-sold">
                                    {{notify.title}}
                                </label>
                            </div>
                            <div class="form-text">{{notify.subtitle}}</div>
                        </div>
                        }
                    </div>
                    <div class="d-flex flex-sm-row flex-column mt-4">
                        <button class="btn btn-accent" type="button">
                            Save changes
                        </button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
<app-footer></app-footer>