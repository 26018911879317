import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Componentes
import { CatalogV1Component } from './catalog-v1/catalog-v1.component';
import { CatalogV2Component } from './catalog-v2/catalog-v2.component';
import { AuctionLiveComponent } from './auction-live/auction-live.component';
import { AuctionEndedComponent } from './auction-ended/auction-ended.component';
import { AuctionBuyComponent } from './auction-buy/auction-buy.component';
import { VendorComponent } from './vendor/vendor.component';
import { ConnectwalletComponent } from './connectwallet/connectwallet.component';
import { CreatenewitemComponent } from './createnewitem/createnewitem.component';

// Guard de Autenticação
import { AuthGuard } from '../../shared/guards/auth.guard';  // Importando o guard

const routes: Routes = [
  {
    path: 'catalog_v1', component: CatalogV1Component
  },
  {
    path: 'catalog_v2', component: CatalogV2Component
  },
  {
    path: 'r/:slug', component: AuctionLiveComponent  // Adiciona o parâmetro dinâmico :slug
  },
  {
    path: 'my-quotas', component: AuctionEndedComponent
  },
  {
    path: 'buy/:id', component: AuctionBuyComponent
  },
  {
    path: 'vendor', component: VendorComponent
  },
  {
    path: 'connectwallet', component: ConnectwalletComponent
  },
  {
    path: 'createnewitem', component: CreatenewitemComponent, canActivate: [AuthGuard]  // Protegendo a rota
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CatalogRoutingModule { }
