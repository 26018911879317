<!-- Page Title -->
<div
  class="container d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between align-items-center mb-2 pt-2">
  <div class="d-flex align-items-center">
    <div class="img-thumbnail rounded-circle position-relative flex-shrink-0" style="width: 6.375rem;">
      <img class="rounded-circle" src="assets/img/nft/vendor/avatar.png" alt="{{userName}}" id="user_profile">
    </div>
    <div class="ps-3">
      <h3 class="h5 mb-2 text-light">{{userName}}</h3> <!-- Nome do usuário atualizado -->

      <!-- Data de inscrição do usuário -->
      <!-- <span class="d-block text-light fs-sm opacity-60">Joined July 2021</span> -->
    </div>
  </div>
  <div class="my-sm-0 my-3 text-sm-end pt-1">
    <div class="d-flex align-items-center text-nowrap fs-sm">
      <div class="mb-2 me-sm-3 me-2 text-muted">
        <!-- Quantidade de seguidores -->
        <!-- <span class='fw-medium text-light'>766</span> -->
        <!-- <span class='text-white opacity-70'>followers</span> -->
      </div>
      <div class="mb-2 ps-sm-3 ps-2 border-start border-light text-muted">
        <!-- Quantidade de pessoas seguidas -->
        <!-- <span class='fw-medium text-light'>2K</span> -->
        <!-- <span class='text-white opacity-70'>following</span> -->
      </div>
    </div>
    <!--<a class="text-light" href="javascript:void(0)">0x1dDB2C08s97...9Ecd
      <i class="ci-copy ms-2 fs-sm" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Copy"></i>
    </a>-->
  </div>
</div>