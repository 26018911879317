import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connectwallet',
  templateUrl: './connectwallet.component.html',
  styleUrls: ['./connectwallet.component.scss']
})

// Connectwallet Component
export class ConnectwalletComponent implements OnInit {

  breadCrumbItems!: any;
  constructor() { }

  ngOnInit(): void {

    // When the user clicks on the button, scroll to the top of the document
    // document.documentElement.scrollTop = 0;

    // Remove header user profile and create button
    document.querySelector('.user')?.classList.add('d-none')
    document.querySelector('.create')?.classList.add('d-none')
    document.querySelector('.craeteitem')?.classList.add('d-none')

    //Remove mail subscription footer
    document.querySelector('.bg-secondary')?.classList.add('d-none')
    document.querySelector('.footer .bg-dark')?.classList.remove('mt-n10', 'pt-10')

    /**
   * BreadCrumb
   */
    this.breadCrumbItems = [
      { label: 'Home', link: '/' },
      { label: 'Marketplace', link: '/' },
      { label: 'Single Project', active: true, link: '/Single Project' }
    ];
  }

}
