import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

// Data Get
import { Catlog2Service } from './catalog-v2.service';
import { Catalog2Model } from './catalog-v2.model';
import { favorite } from '../../account/favorite/data';
import { products } from '../catalog-v1/data';

@Component({
  selector: 'app-catalog-v2',
  templateUrl: './catalog-v2.component.html',
  styleUrls: ['./catalog-v2.component.scss'],
  providers: [Catlog2Service, DecimalPipe]
})

// CatalogV2 Component
export class CatalogV2Component implements OnInit {

  breadCrumbItems!: any;
  allproduct: any;
  products: any;

  // set the current year
  year: number = new Date().getFullYear();
  private _diff?: any;
  _days?: number;
  _hours?: number;
  _minutes?: number;
  _seconds?: number;
  timer: any;

  fixed: any;
  live: any;

  // Table data
  CatelogList!: Observable<Catalog2Model[]>;
  total: Observable<number>;

  constructor(public service: Catlog2Service) {
    this.CatelogList = service.countries$;
    this.total = service.total$;
  }
  ngOnInit(): void {

    // When the user clicks on the button, scroll to the top of the document
    document.documentElement.scrollTop = 0;

    // Remove header user profile and create button
    document.querySelector('.user')?.classList.add('d-none')
    document.querySelector('.create')?.classList.add('d-none')
    document.querySelector('.craeteitem')?.classList.add('d-none')

    /**
  * BreadCrumb
  */
    this.breadCrumbItems = [
      { label: 'Home', link: '/' },
      { label: 'NFT Marketplace', link: '/' },
      { label: 'All NFTs', active: true, link: '/All NFTs' }
    ];

    //Fetch Data
    setTimeout(() => {
      this.CatelogList.subscribe(x => {
        this.allproduct = Object.assign([], x);
        this.products = Object.assign([], x);
      });
      document.getElementById('elmLoader')?.classList.add('d-none')
    }, 1200)
  }

  /**
  * Count date set
  */
  countdown(time: any) {
    if(Date.parse(time) > Date.parse(new Date().toString())){
      this._diff = Date.parse(time) - Date.parse(new Date().toString());
      this._days = Math.floor(this._diff / (1000 * 60 * 60 * 24));
      this._hours = Math.floor((this._diff / (1000 * 60 * 60)) % 24);
      this._minutes = Math.floor((this._diff / 1000 / 60) % 60);
      this._seconds = Math.floor((this._diff / 1000) % 60);
      return ((this._hours < 10) ? '0' + this._hours : this._hours) + ':' + ((this._minutes < 10) ? '0' + this._minutes : this._minutes) + ':' + ((this._seconds < 10) ? '0' + this._seconds : this._seconds)
    }else{
      return '00:00:00'
    }
  }

  // Add to Favorite
  addfavorite(id: any) {
    this.allproduct[id].is_like = '1'
    favorite.push(this.allproduct[id])
  }

  // Filter
  typefilter(ev: any) {
    this.allproduct = products
    if (this.fixed == true && this.live == true) {
      this.allproduct = products
    } else if (this.fixed == true) {
      this.allproduct = this.allproduct.filter((item: any) => {
        return item.type == ev.target.value
      })
    } else if (this.live == true) {
      this.allproduct = this.allproduct.filter((item: any) => {
        return item.type == ev.target.value
      })
    }
    this.service.products = this.allproduct
  }

  creatorfilter(ev: any) {
    this.allproduct = products
    if (ev.target.checked == true) {
      this.allproduct = this.allproduct.filter((item: any) => {
        return item.name == ev.target.value
      })
    }
    this.service.products = this.allproduct
  }

  colllectionchane(ev: any) {
    this.allproduct = products
    if (ev.target.checked == true) {
      this.allproduct = this.allproduct.filter((item: any) => {
        return item.collection == ev.target.value
      })
    }
    this.service.products = this.allproduct
  }

}
