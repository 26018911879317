<!-- Footer-->
<!-- Mail subscription -->
<div class="footer bg-secondary">
    <section class="container">
        <div class="card py-5 border-0 shadow">
            <div class="card-body py-md-4 py-3 px-4 text-center">
                <h3 class="mb-3">Nunca perca um lançamento!</h3>
                <p class="mb-4 pb-2">
                    Inscreva-se em nossa lista exclusiva de lançamentos e seja o primeiro a saber sobre novos
                    lançamentos.
                </p>
                <div class="widget mx-auto" style="max-width: 500px;">
                    <form class="subscription-form validate" (ngSubmit)="subscribe()" [formGroup]="formData"
                        action="https://studio.us12.list-manage.com/subscribe/post?u=c7103e2c981361a6639545bd5&amp;amp;id=29ca296126"
                        method="post" name="mc-embedded-subscribe-form" target="_blank" novalidate>
                        <div class="input-group flex-nowrap">
                            <i class="ci-mail position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                            <!-- <input class="form-control rounded-start" type="email" name="EMAIL" placeholder="Seu e-mail"
                                required> -->
                            <input class="form-control rounded-start" type="email" name="email" placeholder="Seu e-mail"
                                required formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && form['email'].errors }">
                            <button class="btn btn-accent" type="submit" name="subscribe">
                                Inscrever-se*
                            </button>
                        </div>
                        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                        <div style="position: absolute; left: -5000px;" aria-hidden="true">
                            <input class="subscription-form-antispam" type="text"
                                name="b_c7103e2c981361a6639545bd5_29ca296126" tabindex="-1">
                        </div>
                        <div class="form-text mt-3">
                            *Receba ofertas de desconto antecipadas, atualizações e informações sobre novos produtos.
                        </div>
                        <div class="subscription-status"></div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
<footer class="footer bg-darker">
    <div class="mt-n10 pt-10 bg-dark">
        <div class="container py-5">
            <div class="row py-lg-4">
                <div class="col-lg-4 mb-lg-0 mb-4">
                   
                </div>
                <div class="col-lg-6 offset-lg-1">
                    <div class="d-flex flex-sm-row flex-column justify-content-sm-between mt-n4 mx-lg-n3">
                        <div class="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                            <h3 class="widget-title text-light"></h3>
                            <ul class="widget-list">
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link" href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                            </ul>
                        </div>
                        <div class="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                            <h3 class="widget-title text-light"></h3>
                            <ul class="widget-list">
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                                <li class="widget-list-item"><a class="widget-list-link"
                                        href="javascript:void(0);"></a></li>
                            </ul>
                        </div>
                        <div class="widget widget-links widget-light mt-4 px-lg-3 px-sm-n2">
                            
                            <ul class="widget-list">
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4">
        <div class="d-flex flex-md-row flex-column-reverse align-items-center justify-content-md-between">
            <div class="fs-xs text-light opacity-50">&copy; Todos os direitos reservados. Feito por <a
                    class="text-light" href="https://rede5.com.br" target="_blank" rel="noopener">Rede5</a>
            </div>
            <!--<div class="d-flex align-items-start mb-md-0 mb-3 mx-n1">
                <div class="px-1"><a class="btn-market btn-apple bg-dark" href="javascript:void(0);"><span
                            class="btn-market-subtitle">Baixe na</span><span class="btn-market-title">App
                            Store</span></a></div>
                <div class="px-1"><a class="btn-market btn-google bg-dark" href="javascript:void(0);"><span
                            class="btn-market-subtitle">Baixe no</span><span class="btn-market-title">Google
                            Play</span></a></div>
            </div>-->
        </div>
    </div>
</footer>
<div class="handheld-toolbar"></div>
<!-- Back To Top Button-->
<a class="btn-scroll-top" href="javascript:void(0)" [ngxScrollTo]="'#home'" data-scroll>
    <span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Topo</span>
    <i class="btn-scroll-top-icon ci-arrow-up"></i>
</a>