<!-- Alternador do menu da conta (oculto em telas maiores que 992px)-->
<div class="d-block d-lg-none p-4">
    <a class="btn btn-outline-accent d-block" data-bs-target="#navbarCollapse" data-bs-toggle="collapse"
        (click)="collapse.toggle()">
        <i class="ci-menu me-2"></i>Menu da Conta
    </a>
</div>

<!-- Menu real-->
<div class="h-100 border-end mb-2">
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="d-lg-block collapse" id="account-menu">
        <ul class="list-unstyled mb-0">

            <!-- Minha Campanha (anteriormente Meus Itens) movido para o topo -->
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/myitem">
                    <i class="ci-image opacity-60 me-2"></i>Campanhas
                    <span class="fs-sm text-muted ms-auto">{{raffleCount}}</span> <!-- Exibindo a contagem de rifas -->
                </a>
            </li>

            <!-- Configurações de Perfil -->
            <!--
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/setting">
                    <i class="ci-settings opacity-60 me-2"></i>Configurações de Perfil
                </a>
            </li>
            -->

            <!-- Minhas Coleções -->
            <!--
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/mycollection">
                    <i class="ci-view-list opacity-60 me-2"></i>Minhas Coleções
                    <span class="fs-sm text-muted ms-auto">{{collections.length}}</span>
                </a>
            </li>
            -->

            <!-- Favoritos -->
            <!--
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/favorite">
                    <i class="ci-heart opacity-60 me-2"></i>Favoritos
                    <span class="fs-sm text-muted ms-auto">{{favorites.length}}</span>
                </a>
            </li>
            -->

            <!-- Notificações -->
            <!--
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" routerLink="/notification">
                    <i class="ci-bell opacity-60 me-2"></i>Notificações
                </a>
            </li>
            -->

            <!-- Sair -->
            <li class="border-bottom mb-0">
                <a class="nav-link-style d-flex align-items-center px-4 py-3 menulist" (click)="logout()">
                    <i class="ci-sign-out opacity-60 me-2"></i>Sair
                </a>
            </li>

        </ul>
    </div>
</div>