import { Component, OnInit, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router'; // Adicionei ActivatedRoute para verificar parâmetros
import { MENU } from './menu';
import { MenuItem } from './menu.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuItems: MenuItem[] = [];
  isCollapsed = true;

  signinForm!: FormGroup;
  signupForm!: FormGroup;
  fieldTextType: boolean = false;
  signupPassfield: boolean = false;
  submitted = false;
  signupsubmit = false;

  @ViewChild('content', { static: true }) content!: TemplateRef<any>;  // Captura a referência ao modal

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.menuItems = MENU;

    this.signinForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    this.signupForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    // Detecção do parâmetro "login" na URL
    this.route.queryParams.subscribe(params => {
      if (params['login']) {
        console.log('Parâmetro login detectado, abrindo modal de login');
        this.openModal(this.content); // Usando o ViewChild para garantir que o modal seja carregado
      }
    });

    this.setmenuactive();
  }

  @HostListener('window:scroll', [])
  windowScroll(): void {
    const navbar = document.querySelector('.navbar-sticky');
    if (window.scrollY > 300) {
      navbar?.classList.add('navbar-stuck');
    } else {
      navbar?.classList.remove('navbar-stuck');
    }
  }

  openModal(content: any): void {
    console.log('Tentando abrir modal...');
    this.modalService.open(content, { size: 'md', centered: true }).result.then(
      (result) => {
        console.log('Modal fechado com:', result);
      },
      (reason) => {
        console.log('Modal fechado por:', reason);
      }
    );
  }

  signin() {
    this.submitted = true;
    if (this.signinForm.valid) {
      const { email, password } = this.signinForm.value;
      this.authService.login({ email, password }).subscribe(
        (response) => {
          console.log('Logged in successfully', response);
          this.modalService.dismissAll();
          this.router.navigate(['/account/myitem']);
        },
        (error) => {
          console.error('Login failed', error);
        }
      );
    }
  }

  signup() {
    this.signupsubmit = true;
    if (this.signupForm.valid) {
      const { name, email, password } = this.signupForm.value;
      this.authService.register({ full_name: name, email, password }).subscribe(
        (response) => {
          console.log('User registered successfully', response);
          this.modalService.dismissAll();
        },
        (error) => {
          console.error('Registration failed', error);
        }
      );
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  togglesignupPassfield() {
    this.signupPassfield = !this.signupPassfield;
  }

  updateActive(event: Event): void {
    const links = document.querySelectorAll('.navbar-nav .sublink');
    links.forEach(link => link.classList.remove('active'));
    const target = event.target as HTMLElement;
    target.classList.add('active');
  }

  setmenuactive() {
    setTimeout(() => {
      const pathName = window.location.pathname;
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const items = Array.from(ul.querySelectorAll("a.sublink"));
        let matchingMenuItem = items.find((x: any) => x.pathname === pathName);
        this.activateParentDropdown(matchingMenuItem);
      }
    }, 0);
  }

  activateParentDropdown(item: any) {
    item?.classList.add("active");
    const parentCollapseDiv = item?.closest(".dropdown-menu");
    if (parentCollapseDiv) {
      parentCollapseDiv.parentElement.children[0].classList.add("active");
    }
  }
}