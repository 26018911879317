<!-- Header -->
<app-header></app-header>
<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4">
    <app-breadcrumbs title="Vendor Page" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
</div>
<!-- Page content-->
<section class="container mb-4">
    <div class="mt-lg-2"><img class="rounded-circle border border-3 d-lg-block d-none" src="assets/img/nft/vendor/avatar.png" width="140" alt="Avatar" style="border-color: #ffffff !important;">
        <div class="d-lg-none d-flex align-items-center justify-content-center"><img class="rounded-circle me-2" src="assets/img/nft/vendor/avatar-sm.png" width="36" alt="Avatar">
            <h6 class="mb-0 text-light">Robert Fox</h6>
        </div>
    </div>
    <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3 pt-lg-4">
            <div class="offcanvas offcanvas-collapse" id="vendor-sidebar">
                <div class="offcanvas-header align-items-center shadow-sm">
                    <h2 class="h5 mb-0">Vendor</h2>
                    <button class="btn-close ms-auto" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <!-- User avatar--><img class="rounded-circle d-lg-none d-block mb-4" src="assets/img/nft/vendor/avatar.png" width="140" alt="Avatar">
                    <!-- User meta-->
                    <div class="mb-4 pb-2">
                        <h3 class="mb-2 fs-lg fw-normal text-body">Robert Fox</h3>
                        <h2 class="h4 mb-3">&#64;foxnet_creator</h2><span class="d-inline-block mb-3 fs-sm text-muted">Joined July 2021</span><a class="d-block mb-4 text-decoration-none" href="javascript:void(0);"><span class="text-body">8508550793340827...</span><i class="ci-copy ms-2 ps-1 fs-sm text-accent" data-bs-toggle="tooltip" data-bs-placement="top" ngbTooltip="Copy"></i></a>
                        <div class="d-flex align-items-center flex-wrap text-nowrap mb-4 fs-sm">
                            <div class="mb-2 me-sm-3 me-2 text-muted"><span class='fw-medium text-body'>766</span>
                                followers</div>
                            <div class="mb-2 me-sm-3 me-2 ps-sm-3 ps-2 border-start text-muted"><span class='fw-medium text-body'>2K</span> following</div>
                        </div>
                        <!-- Follow-->
                        <div class="d-flex flex-lg-row flex-column">
                            <button class="btn btn-accent">Follow</button>
                        </div>
                    </div>
                </div>
                <div class="offcanvas-footer d-flex flex-column align-items-start p-lg-0">
                    <!-- User bio-->
                    <div class="mb-4 pb-2 fs-sm">
                        <h6 class="mb-2 fs-md">Short bio</h6>
                        <p class="mb-0">Non mi, quis ornare odio. Adipiscing a varius varius interdum. Diam pharetra
                            sapien, eu tristique aliquet.</p><a class="d-inline-block mt-1" href="javascript:void(0);"><ins>Read
                                more</ins></a>
                    </div>
                    <!-- Socials-->
                    <div class="mt-n2"><a class="btn-social bs-twitter me-2 mt-2" href="javascript:void(0);"><i class="ci-twitter"></i></a><a class="btn-social bs-facebook me-2 mt-2" href="javascript:void(0);"><i class="ci-facebook"></i></a><a class="btn-social bs-instagram me-2 mt-2" href="javascript:void(0);"><i class="ci-instagram"></i></a><a class="btn-social bs-youtube me-2 mt-2" href="javascript:void(0);"><i class="ci-youtube"></i></a></div>
                </div>
            </div>
        </aside>
        <!-- Content-->
        <div class="col-lg-9 mt-lg-n5 mt-5 pt-lg-2 pt-3">
            <div class="d-flex flex-md-row flex-column align-items-md-center justify-content-md-between mb-lg-4 mb-3 pb-md-1">
                <!-- Nav tabs-->
                <div class="mb-md-0 mb-4 pb-1" style="overflow-x: auto;">
                    <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-fill flex-nowrap text-nowrap mb-0" role="tablist">
                        <li class="nav-item" [ngbNavItem]="1">
                            <a ngbNavLink class="nav-link" href="javacripts:void(0);" data-bs-toggle="tab" role="tab">Created
                                <span class='opacity-60'>({{created.length}})</span>
                            </a>
                            <ng-template ngbNavContent>
                                <!-- Created items grid-->
                                <div class="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3">
                                    <!-- Product-->
                                    @for(create of created;track $index){
                                    <div>
                                        <div class="col mb-2">
                                            <article class="card h-100 border-0 shadow">
                                                <div class="card-img-top position-relative overflow-hidden"><a class="d-block" routerLink="/auctionlive"><img src="{{create.image}}" alt="Product image"></a>
                                                    <!-- Countdown timer-->
                                                    @if(create.time){
                                                    <div class="badge bg-dark m-3 fs-sm position-absolute top-0 start-0 zindex-5">
                                                        <i class="ci-time me-1"></i>
                                                        <div class="countdown d-inline">
                                                            <span>{{countdown(create.time)}}</span>
                                                        </div>
                                                    </div>
                                                }
                                                    <!-- Wishlist button-->
                                                    <button class="btn-wishlist btn-sm position-absolute top-0 end-0" type="button" data-bs-toggle="tooltip" data-bs-placement="left" ngbTooltip="Add to Favorites" style="margin: 12px;" (click)="addfavorite($index)"><i [ngClass]="(create.is_like == '1')?'ci-heart-filled':'ci-heart'"></i></button>
                                                </div>
                                                <div class="card-body">
                                                    <h3 class="product-title mb-2 fs-base"><a class="d-block text-truncate" routerLink="/auctionlive">{{create.title}}</a>
                                                    </h3><span class="fs-sm text-muted">Current bid:</span>
                                                    <div class="d-flex align-items-center flex-wrap">
                                                        <h4 class="mt-1 mb-0 fs-base text-darker">{{create.currentbid}}
                                                        </h4><span class="mt-1 ms-1 fs-xs text-muted">{{create.bid}}</span>
                                                    </div>
                                                </div>
                                                <div class="card-footer mt-n1 py-0 border-0">
                                                    <div class="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                                        <img class="me-2 rounded-circle" src="{{create.profile}}" width="32" alt="Avatar"><a class="nav-link-style fs-sm stretched-link" routerLink="/vendor">&#64;{{create.name}}</a>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                    }
                                    <!-- Load more-->
                                    <button class="btn btn-outline-accent d-block w-100 mt-sm-4 mt-3"><i class="ci-reload me-2"></i>Load
                                        more</button>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" [ngbNavItem]="2">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">Collections
                                <span class='opacity-60'>({{collections.length}})</span>
                            </a>
                            <ng-template ngbNavContent>
                                <!-- Collections-->
                                <!-- Collections grid-->
                                <div class="row row-cols-md-2 row-cols-1 g-4">
                                    <!-- Collection item-->
                                    @for(collect of collections;track $index){
                                    <div class="col">
                                        <article>
                                            <div class="card mb-3">
                                                <div class="card-body p-3">
                                                    <!-- Author-->
                                                    <div class="d-flex align-items-center position-relative mb-3">
                                                        <img class="rounded-circle me-2" src="{{collect.profile}}" width="32" alt="Avatar">
                                                        <div class="fs-sm">by<a class="ms-1 fw-medium text-accent stretched-link" routerLink="/vendor">&#64;{{collect.name}}</a></div>
                                                    </div>
                                                    <!-- Collage--><a class="d-block" routerLink="/catalog_v2">
                                                        <div class="row row-cols-2 g-2">
                                                            <div class="col"><img class="rounded-1" src="{{collect.image[0]}}" alt="Collection item">
                                                            </div>
                                                            <div class="col">
                                                                <div class="mt-n2"><img class="rounded-1 mt-2" src="{{collect.image[1]}}" alt="Collection item"><img class="rounded-1 mt-2" src="{{collect.image[2]}}" alt="Collection item"></div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <!-- Collection title-->
                                            <h3 class="h5 mb-1"><a class="nav-link-style" routerLink="/catalog_v2">{{collect.title}}</a></h3><span class="fs-sm text-muted">$ {{collect.price}}</span>
                                        </article>
                                    </div>
                                    }
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" [ngbNavItem]="3">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">Liked
                                <span class='opacity-60'>({{liked.length}})</span>
                            </a>
                            <!-- Liked-->
                            <ng-template ngbNavContent>
                                <!-- Liked items grid-->
                                <div class="row row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3">
                                    <!-- Product-->
                                    @for(like of  liked;track $index){
                                    <div class="col mb-2">
                                        <article class="card h-100 border-0 shadow">
                                            <div class="card-img-top position-relative overflow-hidden"><a class="d-block" routerLink="/auctionbuy"><img src="{{like.image}}" alt="Product image"></a>
                                                <!-- Countdown timer-->
                                                @if(like.time){
                                                <div class="badge bg-dark m-3 fs-sm position-absolute top-0 start-0 zindex-5">
                                                    <i class="ci-time me-1"></i>
                                                    <div class="countdown d-inline">
                                                        <span>{{countdown(like.time)}}</span>
                                                    </div>
                                                </div>
                                                }
                                                <!-- Wishlist button-->
                                                <button class="btn-wishlist btn-sm position-absolute top-0 end-0" type="button" data-bs-toggle="tooltip" data-bs-placement="left" ngbTooltip="Remove" style="margin: 12px;" (click)="removefavorite($index)"><i class="ci-heart-filled text-danger"></i></button>
                                            </div>
                                            <div class="card-body">
                                                <h3 class="product-title mb-2 fs-base"><a class="d-block text-truncate" routerLink="/auctionbuy">{{like.title}}</a></h3><span class="fs-sm text-muted">Reserve price:</span>
                                                <div class="d-flex align-items-center flex-wrap">
                                                    <h4 class="mt-1 mb-0 fs-base text-darker">{{like.price}}</h4>
                                                    <span class="mt-1 ms-1 fs-xs text-muted">{{like.dollorprice}}</span>
                                                </div>
                                            </div>
                                            <div class="card-footer mt-n1 py-0 border-0">
                                                <div class="d-flex align-items-center position-relative mb-1 py-3 border-top">
                                                    <img class="me-2 rounded-circle" src="{{like.profile}}" width="32" alt="Avatar"><a class="nav-link-style fs-sm stretched-link" routerLink="/vendor">&#64;{{like.name}}</a>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                    }
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" [ngbNavItem]="4">
                            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">Activity</a>
                            <!-- Activity-->
                            <ng-template ngbNavContent>
                                <!-- Orders list-->
                                <div class="table-responsive fs-md mb-4 pb-1">
                                    <table class="table mb-0" style="min-width: 880px;">
                                        <thead class="text-uppercase bg-secondary">
                                            <tr>
                                                <th>Item</th>
                                                <th>Type</th>
                                                <th>Price</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @for(activity of activities;track $index){
                                            <tr>
                                                <td class="py-4">
                                                    <div class="d-flex align-items-center position-relative"><img class="rounded me-2" src="{{activity.image}}" width="48" alt="Product thumbnail">
                                                        <div class="ps-1">
                                                            <h6 class="mb-0 fs-base text-body"><a class="nav-link-style d-block stretched-link" href="javascript:void(0);">{{activity.title}}</a>
                                                            </h6><span class="fs-sm text-muted">by
                                                                &#64;freeross</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="py-4"><span class="badge bg-{{activity.color}}">{{activity.type}}</span>
                                                </td>
                                                <td class="py-4">
                                                    <h6 class="mb-1 fs-sm text-darker">{{activity.price}}</h6>
                                                    <span class="fs-sm text-muted">{{activity.dollorprice}}</span>
                                                </td>
                                                <td class="py-4"><a class="nav-link-style fw-medium" href="javascript:void(0);">&#64;{{activity.from}}</a>
                                                </td>
                                                <td class="py-4"><a class="nav-link-style fw-medium" href="javascript:void(0);">&#64;{{activity.to}}</a>
                                                </td>
                                                <td class="py-4"><span class="fs-sm text-muted">{{activity.date}}</span>
                                                </td>
                                            </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <button class="btn btn-outline-accent d-block w-100"><i class="ci-reload me-2"></i>Load
                                    more</button>
                            </ng-template>
                        </li>
                    </ul>
                </div>
                <!-- Sorting-->
                <div class="d-flex flex-md-row flex-column align-items-md-center flex-shrink-0 ms-md-4 ps-md-2 pb-1">
                    <label class="form-label mb-md-0 me-md-2 pe-md-1 fs-sm fw-normal text-nowrap" for="sorting">Sort
                        by:</label>
                    <select class="form-select" id="sorting" style="min-width: 222px;">
                        <option selected disabled>Newest</option>
                        <option>Oldest</option>
                    </select>
                </div>
            </div>
            <!-- Tabs content-->
            <div class="tab-content">
                <div [ngbNavOutlet]="nav"></div>
            </div>
        </div>
    </div>
</section>
<!-- Mobile app-->
<section class="container mb-4 py-lg-5 py-4">
    <!-- Banner-->
    <div class="card border-0 position-relative overflow-hidden">
        <!-- Overlay bg--><span class="d-block w-100 h-100 position-absolute top-0 start-0 zindex-1 bg-accent opacity-15" style="background-image: conic-gradient(from 207.95deg at 50% 50%, #885CFF -57.31deg, #FF6B9B 44.14deg, #DC86FF 78.21deg, #885CFF 123.94deg, #DC86FF 186.36deg, #FF6B9B 249.2deg, #885CFF 302.69deg, #FF6B9B 404.14deg);"></span>
        <!-- Overlay content-->
        <div class="card-body row py-0 px-sm-0 position-relative zindex-5">
            <div class="col-lg-9 col-sm-10 offset-sm-1 d-flex flex-md-row flex-column align-items-md-end text-md-start text-center">
                <div class="my-5 me-md-5 me-auto ms-md-0 ms-auto py-md-4" style="max-width: 495px;">
                    <h3 class="h2 mb-3"><span class='text-accent'>Best App</span> for NFT Enthusiasts</h3>
                    <p class="mb-sm-4 mb-3">If you're an NFT enthusiast, or are looking to get more familiar with NFTs,
                        consider downloading our NFT App!</p>
                    <div class="mx-n1"><a class="btn-market btn-apple mt-3 mx-1" href="javascript:void(0);"><span class="btn-market-subtitle">Download on the</span><span class="btn-market-title">App
                                Store</span></a><a class="btn-market btn-google mt-3 mx-1" href="javascript:void(0);"><span class="btn-market-subtitle">Download on the</span><span class="btn-market-title">Google
                                Play</span></a></div>
                </div>
                <div class="flex-shrink-0 ms-md-auto"><img src="assets/img/nft/mobile-app.svg" alt="Illustration" style="border-top-left-radius: 28px; border-top-right-radius: 28px; box-shadow: 16px 16px 24px -7px rgba(0, 0, 0, .3);">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Bg shape-->
<div class="pt-4 bg-secondary">
    <!-- Features-->
    <app-mailfooter></app-mailfooter>
</div>

<app-footer></app-footer>