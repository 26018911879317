import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    console.log('Verificando autenticação...');

    return this.authService.isAuthenticated().pipe(
      tap((isAuthenticated: boolean) => {
        console.log('Status de autenticação:', isAuthenticated);
        if (!isAuthenticated) {
          console.log('Usuário não autenticado, redirecionando para login.');
          // Redirecionando para a URL com o queryParam login=true
          this.router.navigate([''], { queryParams: { login: 'true' } });
        }
      }),
      map((isAuthenticated: boolean) => isAuthenticated)
    );
  }
}
