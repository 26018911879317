<!-- Header -->
<app-header></app-header>
<!-- Page Title -->
<div class="page-title-overlap bg-accent pt-4">
    <app-account-breadcrumbs></app-account-breadcrumbs>
</div>
<div class="container mb-5 pb-3">
    <div class="bg-light shadow-lg rounded-3 overflow-hidden">
        <div class="row">
            <!-- Sidebar -->
            <aside class="col-lg-3 pe-xl-5">
                <app-acount-sidemenu></app-acount-sidemenu>
            </aside>
            <!-- Content -->
            <section class="col-lg-9 pt-lg-4 pb-4 mb-3">
                <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
                    <h1 class="h3 mb-4 pt-2 text-center text-sm-start">Profile Settings</h1>
                    <div class="bg-secondary rounded-3 p-4 mb-4 text-center text-sm-start">
                        <div class="d-flex flex-sm-row flex-column align-items-sm-start align-items-center">
                            <img class="rounded mb-sm-0 mb-3" src="assets/img/nft/vendor/avatar-square.jpg" width="90"
                                alt="Themesbrand" id="user_profile">
                            <div class="ms-n2 ps-sm-4">
                                <label for="customer-image-input" class="btn btn-outline-accent mb-2 ms-2"
                                    type="button"><i class="ci-loading me-2"></i>Change avatar</label>
                                <input class="form-control d-none" id="customer-image-input" type="file"
                                    accept="image/png, image/gif, image/jpeg" (change)="fileChange($event)">
                                <button class="btn btn-outline-danger mb-2 ms-2" type="button">
                                    <i class="ci-trash me-2"></i>
                                    Delete
                                </button>
                                <div class="pt-1 mb-0 fs-sm text-muted">
                                    We recommend an image of at least 400x400. Gifs
                                    work too :)
                                </div>
                            </div>
                        </div>
                    </div>
                    <form (ngSubmit)="saveUser()" [formGroup]="userForm">
                        <div class="row gy-3 mb-4 pb-md-3 mb-2">
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-name">
                                    Full name
                                </label>
                                <input class="form-control" formControlName="name" id="profile-name" type="text"
                                    value="Robert Fox">
                            </div>
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-username">
                                    Username
                                </label>
                                <input class="form-control" id="profile-username" formControlName="username" type="text"
                                    placeholder="@enter_name">
                            </div>
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-uid">
                                    UID
                                </label>
                                <input class="form-control" id="profile-uid" formControlName="uid" type="text"
                                    value="374702749">
                            </div>
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-email">
                                    Email
                                </label>
                                <input class="form-control" id="profile-email" formControlName="email" type="email"
                                    value="example@gmail.com">
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="profile-bio">
                                    Short bio
                                </label>
                                <textarea class="form-control" id="profile-bio" rows="4" formControlName="bio"
                                    placeholder="Tell about yourself in few words"></textarea>
                                <span class="form-text">
                                    0
                                    of 500 characters used.
                                </span>
                            </div>
                        </div>
                        <h3 class="h5 mb-3">Social media profiles</h3>
                        <div class="row gy-3 mb-4 pb-2">
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-website">
                                    Website
                                </label>
                                <input class="form-control" id="profile-website" formControlName="website" type="url"
                                    placeholder="Enter URL">
                            </div>
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-twitter">
                                    Twitter
                                </label>
                                <input class="form-control" id="profile-twitter" formControlName="twitter" type="url"
                                    placeholder="Enter URL">
                            </div>
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-facebook">
                                    Facebook
                                </label>
                                <input class="form-control" id="profile-facebook" formControlName="facebook" type="url"
                                    placeholder="Enter URL">
                            </div>
                            <div class="col-sm-6">
                                <label class="form-label" for="profile-instagram">
                                    Instagram
                                </label>
                                <input class="form-control" id="profile-instagram" formControlName="insta" type="url"
                                    placeholder="Enter URL">
                            </div>
                            <div class="col-12">
                                <a href="javascripts:void(0);">
                                    <i class="ci-add me-2"></i>
                                    <ins>
                                        Add
                                        more
                                    </ins>
                                </a>
                            </div>
                        </div>
                        <!-- Submit -->
                        <div class="d-flex flex-sm-row flex-column">
                            <button class="btn btn-accent" type="submit">
                                Update profile
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </div>
</div>
<!-- Footer -->
<app-footer></app-footer>