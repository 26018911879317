import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; // Para obter o ID do pagamento da URL
import { PaymentService } from '../../../shared/services/payment.service'; // Serviço de pagamento
import { MercadoPagoService } from '../../../shared/services/mercado-pago.service'; // Serviço de Mercado Pago

@Component({
  selector: 'app-auction-buy',
  templateUrl: './auction-buy.component.html',
  styleUrls: ['./auction-buy.component.scss']
})
export class AuctionBuyComponent implements OnInit {
  qrCodeUrl: string = '';
  pixCode: string = '';
  isLoading: boolean = true; // Para controlar o estado de carregamento
  errorMessage: string = ''; // Para exibir mensagens de erro

  constructor(
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private mercadoPagoService: MercadoPagoService // Serviço do Mercado Pago
  ) { }

  ngOnInit(): void {
    document.documentElement.scrollTop = 0;

    // Obtém o ID do pagamento da rota e chama o método para buscar os detalhes
    const paymentId = this.route.snapshot.paramMap.get('id');
    if (paymentId) {
      this.getPaymentDetails(paymentId);
    } else {
      this.isLoading = false;
      this.errorMessage = 'ID do pagamento não encontrado na URL.';
    }
  }

  // Função para buscar os detalhes do pagamento
  getPaymentDetails(paymentId: string): void {
    this.paymentService.getPaymentById(paymentId).subscribe(
      (payment) => {
        console.log('Detalhes do pagamento:', payment);

        // Verifica se existe o payment_gateway_id
        if (payment && payment.payment_gateway_id) {
          this.getMercadoPagoDetails(payment.payment_gateway_id);
        } else {
          this.isLoading = false;
          this.errorMessage = 'payment_gateway_id não encontrado no pagamento.';
          console.error('payment_gateway_id não encontrado no pagamento.');
        }
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage = 'Erro ao obter os detalhes do pagamento.';
        console.error('Erro ao obter os detalhes do pagamento:', error);
      }
    );
  }

  // Função para buscar os detalhes do Mercado Pago usando payment_gateway_id
  getMercadoPagoDetails(paymentGatewayId: string): void {
    this.mercadoPagoService.getPaymentDetails(paymentGatewayId).subscribe(
      (mercadoPagoData: any) => {
        console.log('Dados completos retornados do Mercado Pago:', mercadoPagoData);

        // Verifica se o retorno tem a estrutura e os dados esperados
        if (mercadoPagoData && mercadoPagoData.data) {
          const { qr_code_base64, qr_code } = mercadoPagoData.data;

          if (qr_code_base64 && qr_code) {
            this.qrCodeUrl = `data:image/png;base64,${qr_code_base64}`; // URL da imagem do QR Code
            this.pixCode = qr_code; // Código de Copia e Cola
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.errorMessage = 'Dados do QR Code ou código de copia e cola ausentes.';
            console.error('Dados do QR Code ou código de copia e cola ausentes:', mercadoPagoData.data);
          }
        } else {
          this.isLoading = false;
          this.errorMessage = 'Estrutura de dados inesperada retornada pelo Mercado Pago.';
          console.error('Estrutura de dados inesperada retornada pelo Mercado Pago:', mercadoPagoData);
        }
      },
      (error) => {
        this.isLoading = false;
        this.errorMessage = 'Erro ao obter os dados do Mercado Pago.';
        console.error('Erro ao obter os dados do Mercado Pago:', error);
      }
    );
  }

  // Função para copiar o código PIX para o clipboard
  copyToClipboard(): void {
    if (!this.pixCode) {
      alert('Código PIX indisponível para copiar.');
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = this.pixCode; // Código PIX
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert('Código PIX copiado!');
  }
}